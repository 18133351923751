/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

@font-face {
  font-family: 'Noto Sans';
  src: url(fonts/NotoSans-Regular.ttf) format("truetype");
  font-weight: normal; }

@font-face {
  font-family: 'Noto Sans';
  src: url(fonts/NotoSans-Bold.ttf) format("truetype");
  font-weight: bold; }

body {
  background-color: #0F0E21;
  color: #FFFFFF;
  font-family: 'Noto Sans', sans-serif;
  min-width: 320px;
  overflow-x: hidden; }

input {
  border-radius: 0; }

input[type="search"] {
  -webkit-appearance: none; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */ }

html {
  overflow-x: hidden;
  scroll-behavior: smooth; }

.wow {
  visibility: hidden; }

a {
  text-decoration: none; }

h2 {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 158.2%;
  /* identical to box height, or 54px */
  color: #FFFFFF; }
  @media all and (max-width: 650px) {
    h2 {
      font-size: 24px; } }

header {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 63px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 999;
  -webkit-transition: all 1.5s ease-out;
  -o-transition: all 1.5s ease-out;
  transition: all 1.5s ease-out; }
  header.active {
    background-color: rgba(109, 182, 227, 0.6);
    opacity: 0.8; }
  header #logo {
    width: 635px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    @media all and (max-width: 1200px) {
      header #logo {
        width: 480px; } }
    @media all and (max-width: 960px) {
      header #logo {
        width: 100%;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center; } }
    @media all and (max-width: 340px) {
      header #logo {
        width: 100%;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        margin-left: 10px; } }
  header #items {
    width: 560px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media all and (max-width: 1200px) {
      header #items {
        width: 480px; } }
    @media all and (max-width: 960px) {
      header #items {
        display: none; } }
    header #items .item {
      font-family: 'Noto Sans', sans-serif;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 158.2%;
      color: #FFFFFF !important;
      text-align: center; }
      header #items .item:first-child {
        text-align: left; }
      header #items .item:last-child {
        text-align: right; }
      header #items .item:visited {
        color: #6DB6E3; }
      header #items .item:hover span {
        position: relative; }
        header #items .item:hover span::after {
          content: "";
          width: 25px;
          height: 1px;
          background: #FFFFFF;
          position: absolute;
          right: calc(50% - 12px);
          top: 110%; }

.screen {
  width: 100%;
  height: 100vh;
  min-height: 700px;
  max-width: 2000px; }
  @media all and (min-width: 2000px) {
    .screen {
      margin: 0 auto; } }

#first-screen {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  #first-screen p {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 158.2%;
    text-align: center;
    position: relative; }
    @media all and (max-width: 960px) {
      #first-screen p {
        font-size: 34px; } }
    @media all and (max-width: 450px) {
      #first-screen p {
        font-size: 24px; } }
  #first-screen .background-pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -99; }
    #first-screen .background-pattern img {
      width: 100%; }
      @media all and (max-width: 1550px) {
        #first-screen .background-pattern img {
          width: auto; } }

#crypto-platform {
  max-width: 567px;
  min-width: 288px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1; }

@media all and (max-width: 960px) {
  .crypto {
    height: 100%;
    min-height: auto;
    padding: 150px 0 90px; } }

.crypto-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 150px; }
  .crypto-2 .background-pattern {
    top: -150px !important; }
    @media all and (max-width: 620px) {
      .crypto-2 .background-pattern {
        top: 50px; } }
  @media all and (max-width: 620px) {
    .crypto-2 {
      margin-bottom: 100px; } }
  .crypto-2 .crypto-container {
    height: 830px;
    margin-top: 20px;
    max-width: 1200px; }
    @media all and (max-width: 1550px) {
      .crypto-2 .crypto-container {
        height: 487px;
        max-width: initial;
        width: 100%; } }
    @media all and (max-width: 960px) {
      .crypto-2 .crypto-container {
        height: 569px;
        max-width: initial; } }
    @media all and (max-width: 620px) {
      .crypto-2 .crypto-container {
        height: auto;
        max-width: initial;
        width: 100%; } }

.crypto-platform {
  position: relative;
  height: 100%;
  padding-top: 180px;
  padding-left: 150px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: -webkit-gradient(linear, right top, left top, color-stop(46.69%, rgba(123, 191, 159, 0.6)), color-stop(97.56%, rgba(123, 191, 159, 0)));
  background: -webkit-linear-gradient(right, rgba(123, 191, 159, 0.6) 46.69%, rgba(123, 191, 159, 0) 97.56%);
  background: -o-linear-gradient(right, rgba(123, 191, 159, 0.6) 46.69%, rgba(123, 191, 159, 0) 97.56%);
  background: linear-gradient(270deg, rgba(123, 191, 159, 0.6) 46.69%, rgba(123, 191, 159, 0) 97.56%); }
  @media all and (max-width: 1550px) {
    .crypto-platform {
      padding: 60px 10px 60px 40px;
      margin-right: 20px;
      background: -webkit-gradient(linear, right top, left top, color-stop(46.69%, rgba(123, 191, 159, 0.3)), color-stop(97.56%, rgba(123, 191, 159, 0)));
      background: -webkit-linear-gradient(right, rgba(123, 191, 159, 0.3) 46.69%, rgba(123, 191, 159, 0) 97.56%);
      background: -o-linear-gradient(right, rgba(123, 191, 159, 0.3) 46.69%, rgba(123, 191, 159, 0) 97.56%);
      background: linear-gradient(270deg, rgba(123, 191, 159, 0.3) 46.69%, rgba(123, 191, 159, 0) 97.56%); } }
  @media all and (max-width: 620px) {
    .crypto-platform {
      padding-left: 0;
      width: 100%;
      text-align: center;
      margin-top: 60px;
      margin-right: 0;
      padding-top: 40px;
      padding-bottom: 40px;
      overflow: hidden;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(46.69%, rgba(123, 191, 159, 0.3)), color-stop(97.56%, rgba(123, 191, 159, 0)));
      background: -webkit-linear-gradient(top, rgba(123, 191, 159, 0.3) 46.69%, rgba(123, 191, 159, 0) 97.56%);
      background: -o-linear-gradient(top, rgba(123, 191, 159, 0.3) 46.69%, rgba(123, 191, 159, 0) 97.56%);
      background: linear-gradient(180deg, rgba(123, 191, 159, 0.3) 46.69%, rgba(123, 191, 159, 0) 97.56%); } }
  .crypto-platform svg {
    margin-bottom: 40px; }
    @media all and (max-width: 960px) {
      .crypto-platform svg {
        margin: 0 auto 40px;
        display: block; } }
  .crypto-platform > p#text {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 158.2%;
    color: #FFFFFF;
    text-align: left;
    max-width: 480px; }
    @media all and (max-width: 1550px) {
      .crypto-platform > p#text {
        max-width: 375px; } }
    @media all and (max-width: 960px) {
      .crypto-platform > p#text {
        max-width: 300px;
        text-align: center; } }
    @media all and (max-width: 620px) {
      .crypto-platform > p#text {
        margin: auto; } }
  .crypto-platform > p#text:last-child {
    margin-top: 52px; }
    @media all and (max-width: 620px) {
      .crypto-platform > p#text:last-child {
        margin-top: 30px; } }

.crypto-section {
  max-width: 1200px;
  width: 100%;
  margin-left: 10px; }
  @media all and (max-width: 620px) {
    .crypto-section {
      text-align: center; } }

.crypto-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%; }
  @media all and (max-width: 1550px) {
    .crypto-container {
      max-width: 960px; } }
  @media all and (max-width: 960px) {
    .crypto-container {
      max-width: 960px; } }
  @media all and (max-width: 960px) {
    .crypto-container {
      max-width: 611px; } }
  @media all and (max-width: 620px) {
    .crypto-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }

.crypto-title {
  font-weight: bold;
  color: #6DB6E3;
  font-size: 52px;
  line-height: 158.2%;
  max-width: 688px; }
  @media all and (max-width: 1550px) {
    .crypto-title {
      font-size: 34px;
      line-height: 158.2%;
      max-width: 470px; } }
  @media all and (max-width: 960px) {
    .crypto-title {
      font-size: 24px;
      line-height: 158.2%;
      max-width: 342px;
      text-align: center; } }
  @media all and (max-width: 500px) {
    .crypto-title {
      font-size: 24px;
      line-height: 158.2%; } }

.crypto-image img {
  width: 424.47px; }
  @media all and (max-width: 960px) {
    .crypto-image img {
      width: 275.19px; } }

.crypto-container {
  max-width: 1195px; }

.benefits {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  border-bottom: 3px solid white;
  padding-bottom: 150px; }
  @media all and (max-width: 960px) {
    .benefits {
      padding-left: 20px;
      padding-right: 20px; } }
  @media all and (max-width: 620px) {
    .benefits {
      padding-bottom: 70px; } }
  .benefits .crypto-section {
    margin-bottom: 50px;
    margin-left: 0; }
    @media all and (max-width: 620px) {
      .benefits .crypto-section {
        margin-left: 10px; } }
  .benefits .block-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 1200px;
    width: 100%; }
    @media all and (max-width: 1550px) {
      .benefits .block-1 {
        max-width: 960px; } }
    @media all and (max-width: 620px) {
      .benefits .block-1 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .benefits .block-2 {
    margin-top: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 1200px;
    width: 100%; }
    @media all and (max-width: 1550px) {
      .benefits .block-2 {
        max-width: 960px; } }
    @media all and (max-width: 620px) {
      .benefits .block-2 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse; } }
    @media all and (max-width: 1200px) {
      .benefits .block-2 .description {
        margin-right: 80px; } }
    @media all and (max-width: 960px) {
      .benefits .block-2 .description {
        margin-right: 20px;
        margin-left: 0;
        max-width: 250px; } }
    @media all and (max-width: 620px) {
      .benefits .block-2 .description {
        margin: 50px 0 0; } }
  .benefits .block-1 .description {
    margin-left: 110px; }
    @media all and (max-width: 1550px) {
      .benefits .block-1 .description {
        margin-left: 40px; } }
    @media all and (max-width: 620px) {
      .benefits .block-1 .description {
        margin: 50px 0 0; } }
  .benefits .description {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1; }
    @media all and (max-width: 960px) {
      .benefits .description {
        margin-left: 10px;
        max-width: 300px; } }
    @media all and (max-width: 620px) {
      .benefits .description {
        margin-top: 50px; } }
    @media all and (max-width: 960px) {
      .benefits .description > img {
        display: block;
        margin: 0 auto; } }
    .benefits .description img#benefit-one,
    .benefits .description img#benefit-two {
      margin-top: 60px; }
      @media all and (max-width: 960px) {
        .benefits .description img#benefit-one,
        .benefits .description img#benefit-two {
          margin: 60px auto 0;
          width: 100%; } }
      .benefits .description img#benefit-one:first-child,
      .benefits .description img#benefit-two:first-child {
        margin-top: 0; }
    .benefits .description p {
      font-family: Noto Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 158.2%;
      color: #FFFFFF;
      margin: 40px 0 60px;
      max-width: 450px; }
      @media all and (max-width: 960px) {
        .benefits .description p {
          max-width: 298px;
          text-align: center;
          margin: 20px 0 30px; } }

.view {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }
  .view.with-margin {
    margin-top: 180px;
    margin-bottom: 150px; }
    @media all and (max-width: 620px) {
      .view.with-margin {
        margin-top: 60px;
        margin-bottom: 55px; } }
    @media all and (max-width: 960px) {
      .view.with-margin .wrap .main-title {
        margin: 0 20px 50px 0; } }
    @media all and (max-width: 620px) {
      .view.with-margin .wrap .main-title {
        margin: 0; } }
    @media all and (max-width: 620px) {
      .view.with-margin .wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse; } }
    @media all and (max-width: 960px) {
      .view.with-margin .wrap:last-child {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
            -ms-flex-direction: row;
                flex-direction: row; } }
    @media all and (max-width: 620px) {
      .view.with-margin .wrap:last-child {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
    @media all and (max-width: 960px) {
      .view.with-margin .wrap:last-child div:first-child {
        margin-left: 0;
        margin-right: 20px;
        min-height: 480px;
        background-size: auto 100%; } }
    @media all and (max-width: 620px) {
      .view.with-margin .wrap:last-child div:first-child {
        margin: 0 0 30px; } }
  .view .wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 60px; }
    @media all and (max-width: 960px) {
      .view .wrap:last-child > div:first-child {
        margin-top: -280px;
        min-height: 516px; } }
    @media all and (max-width: 620px) {
      .view .wrap:last-child > div:first-child {
        margin-top: 0; } }
    @media all and (max-width: 960px) {
      .view .wrap {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse;
        margin-bottom: -5px; } }
    @media all and (max-width: 620px) {
      .view .wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-bottom: 30px; } }
    .view .wrap > div {
      text-align: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
          -ms-flex: 1;
              flex: 1;
      max-width: 570px;
      width: 100%;
      height: 350px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 158.2%;
      color: #FFFFFF;
      padding: 0 82px 0 80px; }
      @media all and (max-width: 1550px) {
        .view .wrap > div {
          padding: 16px 45px 0 17px;
          max-width: 468px; } }
      @media all and (max-width: 960px) {
        .view .wrap > div {
          padding: 30px 8px 30px 14px;
          max-width: 288px;
          height: 480px;
          text-align: center; } }
      @media all and (max-width: 620px) {
        .view .wrap > div {
          padding: 72px 16px 30px 16px; } }
      .view .wrap > div:first-child {
        margin-right: 60px; }
        @media all and (max-width: 960px) {
          .view .wrap > div:first-child {
            margin-right: 0;
            margin-left: 20px; } }
        @media all and (max-width: 620px) {
          .view .wrap > div:first-child {
            margin-left: 0;
            padding-top: 30px;
            padding-bottom: 30px; } }
    @media all and (max-width: 960px) {
      .view .wrap .section-problem {
        margin-bottom: 60px; } }
    @media all and (max-width: 620px) {
      .view .wrap .section-problem {
        margin-bottom: 0; } }
    .view .wrap .section-solution {
      text-align: center; }
    .view .wrap .section-back {
      background: url(img/section-back.png) no-repeat center center;
      background-size: auto 100%; }
      @media all and (max-width: 960px) {
        .view .wrap .section-back {
          min-height: 400px; } }
      @media all and (max-width: 620px) {
        .view .wrap .section-back {
          margin-top: 30px;
          min-height: 480px; } }
      .view .wrap .section-back-2 {
        background: url(img/section-back-2.png) no-repeat center center;
        background-size: auto 100%;
        color: black; }
        @media all and (max-width: 960px) {
          .view .wrap .section-back-2 {
            margin: 0 !important; } }
      .view .wrap .section-back-3 {
        background: url(img/section-back-3.png) no-repeat center center;
        background-size: auto 100%;
        color: black; }
        @media all and (max-width: 620px) {
          .view .wrap .section-back-3 {
            margin-top: 30px; } }

.main-title {
  font-weight: bold !important;
  font-size: 52px !important;
  line-height: 158.2% !important; }
  @media all and (max-width: 1550px) {
    .main-title {
      font-size: 34px !important;
      line-height: 158.2% !important;
      margin: 0; } }
  @media all and (max-width: 960px) {
    .main-title {
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 50px; } }

#problems {
  position: relative;
  margin-top: 50px; }
  @media all and (max-width: 960px) {
    #problems {
      margin-top: 0; } }
  #problems svg:nth-child(1) {
    position: absolute;
    top: 250px;
    left: calc((100vw - 1200px) / -2); }
    @media all and (max-width: 1200px) {
      #problems svg:nth-child(1) {
        left: 0; } }
  #problems svg:nth-child(2) {
    position: absolute;
    top: 850px;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    z-index: -1;
    opacity: .7; }
  #problems svg:nth-child(3) {
    position: absolute;
    top: 250px;
    right: calc((100vw - 1200px) / -2); }
    @media all and (max-width: 1550px) {
      #problems svg:nth-child(3) {
        width: 377px;
        height: 427.05px;
        top: 450px;
        right: -60px; } }
    @media all and (max-width: 960px) {
      #problems svg:nth-child(3) {
        width: 313.82px;
        height: 355.48px;
        top: 800px;
        right: 260px; } }
    @media all and (max-width: 620px) {
      #problems svg:nth-child(3) {
        top: auto;
        bottom: -150px;
        right: -50px; } }

#benefit-one,
#benefit-two {
  overflow: hidden; }
  #benefit-one:hover img,
  #benefit-two:hover img {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1); }
  #benefit-one img,
  #benefit-two img {
    margin: 0 !important;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease; }

#benefit-one,
#benefit-two,
#crypto-platform,
#crypto-image,
#how-it-works {
  height: auto; }
  #benefit-one img,
  #benefit-two img,
  #crypto-platform img,
  #crypto-image img,
  #how-it-works img {
    margin: 0 auto;
    max-height: 100%; }
  #benefit-one .max,
  #benefit-two .max,
  #crypto-platform .max,
  #crypto-image .max,
  #how-it-works .max {
    display: block; }
  #benefit-one .middle,
  #benefit-two .middle,
  #crypto-platform .middle,
  #crypto-image .middle,
  #how-it-works .middle {
    display: none; }
  #benefit-one .low-middle,
  #benefit-two .low-middle,
  #crypto-platform .low-middle,
  #crypto-image .low-middle,
  #how-it-works .low-middle {
    display: none; }
  #benefit-one .small,
  #benefit-two .small,
  #crypto-platform .small,
  #crypto-image .small,
  #how-it-works .small {
    display: none; }
  @media all and (max-width: 1550px) {
    #benefit-one img,
    #benefit-two img,
    #crypto-platform img,
    #crypto-image img,
    #how-it-works img {
      margin: 0 20px;
      width: calc(100% - 40px);
      max-height: none; }
    #benefit-one .max,
    #benefit-two .max,
    #crypto-platform .max,
    #crypto-image .max,
    #how-it-works .max {
      display: none; }
    #benefit-one .middle,
    #benefit-two .middle,
    #crypto-platform .middle,
    #crypto-image .middle,
    #how-it-works .middle {
      display: block; }
    #benefit-one .low-middle,
    #benefit-two .low-middle,
    #crypto-platform .low-middle,
    #crypto-image .low-middle,
    #how-it-works .low-middle {
      display: none; }
    #benefit-one .small,
    #benefit-two .small,
    #crypto-platform .small,
    #crypto-image .small,
    #how-it-works .small {
      display: none; } }
  @media all and (max-width: 960px) {
    #benefit-one img,
    #benefit-two img,
    #crypto-platform img,
    #crypto-image img,
    #how-it-works img {
      margin: 0 auto;
      max-height: 100%;
      width: initial; }
    #benefit-one .max,
    #benefit-two .max,
    #crypto-platform .max,
    #crypto-image .max,
    #how-it-works .max {
      display: none; }
    #benefit-one .middle,
    #benefit-two .middle,
    #crypto-platform .middle,
    #crypto-image .middle,
    #how-it-works .middle {
      display: none; }
    #benefit-one .low-middle,
    #benefit-two .low-middle,
    #crypto-platform .low-middle,
    #crypto-image .low-middle,
    #how-it-works .low-middle {
      display: block; }
    #benefit-one .small,
    #benefit-two .small,
    #crypto-platform .small,
    #crypto-image .small,
    #how-it-works .small {
      display: none; } }
  @media all and (max-width: 500px) {
    #benefit-one .max,
    #benefit-two .max,
    #crypto-platform .max,
    #crypto-image .max,
    #how-it-works .max {
      display: none; }
    #benefit-one .middle,
    #benefit-two .middle,
    #crypto-platform .middle,
    #crypto-image .middle,
    #how-it-works .middle {
      display: none; }
    #benefit-one .low-middle,
    #benefit-two .low-middle,
    #crypto-platform .low-middle,
    #crypto-image .low-middle,
    #how-it-works .low-middle {
      display: none; }
    #benefit-one .small,
    #benefit-two .small,
    #crypto-platform .small,
    #crypto-image .small,
    #how-it-works .small {
      display: block; } }

#product {
  background-image: url(img/product-bg.png);
  min-height: 950px;
  height: 100%;
  margin-bottom: 250px; }
  @media all and (max-width: 980px) {
    #product {
      min-height: 750px;
      margin-bottom: 40px; } }
  @media all and (max-width: 620px) {
    #product {
      min-height: 1000px;
      margin-bottom: 100px; } }
  #product .main-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0 20px 180px;
    max-width: 1000px;
    padding-top: 250px; }
    @media all and (max-width: 960px) {
      #product .main-info {
        padding-top: 156px; } }
    @media all and (min-width: 1250px) {
      #product .main-info {
        max-width: 1200px; } }
    @media all and (min-width: 1040px) {
      #product .main-info {
        margin: 0 auto 180px; } }
    @media all and (max-width: 980px) {
      #product .main-info {
        margin: 0 20px 60px; } }
    @media all and (max-width: 620px) {
      #product .main-info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center; }
        #product .main-info .title {
          max-width: none !important; }
        #product .main-info .description {
          max-width: none !important; } }
    #product .main-info .title {
      max-width: 575px; }
      @media all and (max-width: 1250px) {
        #product .main-info .title {
          max-width: 470px; } }
      @media all and (max-width: 900px) {
        #product .main-info .title {
          max-width: 280px;
          font-size: 24px; } }
    #product .main-info .description {
      margin-top: 15px;
      max-width: 575px;
      font-family: 'Noto Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 158.2%;
      text-transform: uppercase;
      color: #6DB6E3; }
      @media all and (max-width: 1250px) {
        #product .main-info .description {
          max-width: 384px; } }
      @media all and (max-width: 900px) {
        #product .main-info .description {
          max-width: 280px;
          margin-top: 0; } }
      @media all and (max-width: 620px) {
        #product .main-info .description {
          margin-top: 50px; } }
      #product .main-info .description .items {
        margin-top: 50px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between; }
        @media all and (max-width: 1250px) {
          #product .main-info .description .items {
            margin-top: 40px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
                -ms-flex-direction: column;
                    flex-direction: column;
            min-height: 112px; } }
        #product .main-info .description .items .item {
          font-size: 16px; }
  #product .schema {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0 auto;
    max-width: 1000px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 360px;
    width: 100%;
    padding: 0 20px; }
    @media all and (min-width: 1250px) {
      #product .schema {
        max-width: 1200px; } }
    @media all and (max-width: 980px) {
      #product .schema {
        height: 240px; } }
    @media all and (max-width: 620px) {
      #product .schema {
        height: 450px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse;
        position: relative;
        padding: 0; } }
    #product .schema .items {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      @media all and (max-width: 620px) {
        #product .schema .items {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
              -ms-flex-direction: column;
                  flex-direction: column;
          width: 100%;
          padding: 0 20px; } }
    #product .schema .block-item {
      width: 280px;
      height: 250px;
      line-height: 158.2%;
      font-weight: 500;
      font-size: 20px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      text-transform: uppercase;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start;
      text-align: left;
      padding-left: 75px; }
      @media all and (max-width: 980px) {
        #product .schema .block-item {
          width: 180px;
          height: 150px;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-direction: row;
              -ms-flex-direction: row;
                  flex-direction: row;
          padding-left: 0;
          font-size: 14px;
          padding-top: 25px; } }
      @media all and (max-width: 620px) {
        #product .schema .block-item {
          width: 100%;
          height: 120px;
          -webkit-box-pack: start;
          -webkit-justify-content: flex-start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          padding-top: 20px;
          padding-left: calc(100% - 170px); } }
      #product .schema .block-item svg {
        margin-bottom: 10px; }
        @media all and (max-width: 980px) {
          #product .schema .block-item svg {
            margin: 0 10px 0 0; } }
  #product .block {
    position: relative;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    /* identical to box height, or 54px */
    color: #FFFFFF; }
    #product .block .bg-image {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0; }
    @media all and (max-width: 980px) and (min-width: 620px) {
      #product .block.block-item::after {
        background: -webkit-gradient(linear, left top, left bottom, color-stop(46.69%, rgba(123, 191, 159, 0.6)), color-stop(97.56%, rgba(123, 191, 159, 0)));
        background: -webkit-linear-gradient(top, rgba(123, 191, 159, 0.6) 46.69%, rgba(123, 191, 159, 0) 97.56%);
        background: -o-linear-gradient(top, rgba(123, 191, 159, 0.6) 46.69%, rgba(123, 191, 159, 0) 97.56%);
        background: linear-gradient(0.5turn, rgba(123, 191, 159, 0.6) 46.69%, rgba(123, 191, 159, 0) 97.56%); } }
    #product .block::after {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: -webkit-gradient(linear, right top, left top, color-stop(46.69%, rgba(123, 191, 159, 0.6)), color-stop(97.56%, rgba(123, 191, 159, 0)));
      background: -webkit-linear-gradient(right, rgba(123, 191, 159, 0.6) 46.69%, rgba(123, 191, 159, 0) 97.56%);
      background: -o-linear-gradient(right, rgba(123, 191, 159, 0.6) 46.69%, rgba(123, 191, 159, 0) 97.56%);
      background: linear-gradient(0.75turn, rgba(123, 191, 159, 0.6) 46.69%, rgba(123, 191, 159, 0) 97.56%);
      opacity: 0.5; }
  #product .block-title {
    height: 100px;
    font-weight: bold;
    font-size: 34px;
    line-height: 158.2%;
    color: #FFFFFF;
    text-align: center;
    max-width: 1000px;
    width: 100%;
    padding: 24px 20px; }
    @media all and (min-width: 1250px) {
      #product .block-title {
        max-width: 1200px; } }
    @media all and (max-width: 980px) {
      #product .block-title {
        font-size: 20px; } }
    @media all and (max-width: 620px) {
      #product .block-title {
        -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
                transform: rotate(90deg);
        position: absolute;
        right: calc(100% - 280px);
        top: 190px;
        width: 450px;
        height: 65px;
        line-height: 65px;
        padding: 0; } }

#team {
  color: #0F0E21;
  height: auto; }
  @media all and (max-width: 990px) {
    #team {
      height: 800px; } }
  @media all and (max-width: 480px) {
    #team {
      height: 1300px; } }
  #team > * {
    width: 1000px;
    margin: 0 auto; }
    @media all and (max-width: 480px) {
      #team > * {
        padding: 40px 10px 0 !important;
        height: auto !important; } }
    @media all and (max-width: 990px) {
      #team > * {
        width: inherit; } }
    @media all and (min-width: 1250px) {
      #team > * {
        width: 1200px; } }
  #team.screen {
    background-color: #FFFFFF; }
  #team h3 {
    height: 250px;
    padding-left: 20px;
    padding-top: 140px;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 158.2%; }
    @media all and (max-width: 960px) {
      #team h3 {
        padding-top: 50px;
        height: 135px; } }
  #team .workers {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    @media all and (max-width: 990px) {
      #team .workers {
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        width: inherit; } }
    #team .workers .worker {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      width: 250px; }
      @media all and (max-width: 990px) {
        #team .workers .worker {
          width: 50%;
          margin-bottom: 60px; } }
      @media all and (max-width: 480px) {
        #team .workers .worker {
          width: 100%; } }
      #team .workers .worker:nth-of-type(1) .img-wrap {
        padding-left: 65px; }
      #team .workers .worker:nth-of-type(2) .img-wrap {
        padding-left: 115px; }
        @media all and (max-width: 960px) {
          #team .workers .worker:nth-of-type(2) .img-wrap {
            padding-left: 35px; } }
      #team .workers .worker:nth-of-type(3) .img-wrap {
        padding-left: 65px; }
        @media all and (max-width: 1200px) {
          #team .workers .worker:nth-of-type(3) .img-wrap {
            padding-left: 45px; } }
      #team .workers .worker:nth-of-type(4) .img-wrap {
        padding-right: 15px; }
      #team .workers .worker .img-wrap {
        height: 148px;
        position: relative;
        background-color: #4F519C; }
        @media all and (max-width: 990px) {
          #team .workers .worker .img-wrap {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
                -ms-flex-pack: center;
                    justify-content: center; } }
        #team .workers .worker .img-wrap img {
          position: absolute;
          bottom: 0; }
      #team .workers .worker p {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 24px auto 0;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 158.2%;
        /* or 22px */
        text-transform: uppercase;
        /* 5 */
        color: #0F0E21; }
        @media all and (max-width: 990px) {
          #team .workers .worker p {
            text-align: center; } }
        #team .workers .worker p .name {
          font-family: 'Noto Sans', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 158.2%;
          /* identical to box height, or 32px */
          text-transform: uppercase;
          color: #0F0E21; }
        #team .workers .worker p .position {
          color: #7BBF9F; }

footer {
  overflow: hidden; }
  footer .background-pattern {
    bottom: 0; }
    @media all and (max-width: 1200px) {
      footer .background-pattern img {
        height: 100%; } }
  footer .footer__container {
    max-height: 700px;
    height: 700px;
    overflow: hidden;
    width: 100%;
    max-width: 1200px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 150px; }
    @media all and (max-width: 1200px) {
      footer .footer__container {
        max-height: 845px;
        height: 845px;
        max-width: 960px;
        margin: 0 20px 150px; } }
    @media all and (max-width: 960px) {
      footer .footer__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        max-height: 1158px;
        height: 1158px;
        margin: 0 20px 30px; } }
    @media all and (max-width: 620px) {
      footer .footer__container {
        max-height: 1228px;
        height: 1228px; } }
  footer .footer__feedback {
    width: 100%;
    max-width: 568px; }
    @media all and (max-width: 1200px) {
      footer .footer__feedback {
        max-width: 470px; } }
    @media all and (max-width: 960px) {
      footer .footer__feedback {
        max-width: 100%;
        text-align: center;
        margin-bottom: 150px; } }
    @media all and (max-width: 620px) {
      footer .footer__feedback {
        margin-bottom: 100px; } }
    footer .footer__feedback h2 {
      font-weight: bold;
      font-size: 34px;
      line-height: 158.2%;
      margin: 0 0 17px; }
  footer .footer__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 158.2%;
    margin: 0 0 60px; }
  footer .footer__contact {
    max-width: 148px;
    text-align: right; }
    @media all and (max-width: 960px) {
      footer .footer__contact {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between; } }
    @media all and (max-width: 620px) {
      footer .footer__contact {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center; } }
    footer .footer__contact-top {
      margin-bottom: 140px; }
      @media all and (max-width: 960px) {
        footer .footer__contact-top {
          margin-bottom: 0; } }
      @media all and (max-width: 620px) {
        footer .footer__contact-top {
          text-align: center;
          margin-bottom: 50px; } }
      footer .footer__contact-top .logo {
        margin-bottom: 60px; }
        @media all and (max-width: 960px) {
          footer .footer__contact-top .logo {
            display: none; } }
    footer .footer__contact-bottom {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      @media all and (max-width: 620px) {
        footer .footer__contact-bottom {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-direction: row;
              -ms-flex-direction: row;
                  flex-direction: row;
          width: 100%; } }
      footer .footer__contact-bottom .logo {
        display: none; }
        @media all and (max-width: 960px) {
          footer .footer__contact-bottom .logo {
            display: block; } }
  footer .footer__info {
    font-weight: 500;
    font-size: 16px;
    line-height: 158.2%;
    margin: 0 0 30px; }
  footer .footer__field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    margin-bottom: 30px;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media all and (max-width: 960px) {
      footer .footer__field {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-bottom: 40px; } }
    footer .footer__field label {
      font-weight: 500;
      font-size: 16px;
      line-height: 158.2%;
      color: #fff; }
      @media all and (max-width: 960px) {
        footer .footer__field label {
          margin-bottom: 20px; } }
    footer .footer__field input {
      height: 50px;
      width: 416px;
      border: 1px solid #6DB6E3;
      border-radius: 1px !important;
      background-color: transparent;
      padding: 15px 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 158.2%;
      color: #FFFFFF;
      outline: none !important; }
      footer .footer__field input::-webkit-input-placeholder {
        color: #fff;
        opacity: .7; }
      footer .footer__field input:-ms-input-placeholder {
        color: #fff;
        opacity: .7; }
      footer .footer__field input::-ms-input-placeholder {
        color: #fff;
        opacity: .7; }
      footer .footer__field input::placeholder {
        color: #fff;
        opacity: .7; }
      @media all and (max-width: 1200px) {
        footer .footer__field input {
          width: 320px; } }
      @media all and (max-width: 960px) {
        footer .footer__field input {
          width: 100%; } }
  footer .footer__button {
    font-weight: 500;
    font-size: 14px;
    line-height: 158.2%;
    text-align: center;
    text-transform: uppercase;
    color: #0F0E21;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #6DB6E3), to(#6DB6E3));
    background: -webkit-linear-gradient(top, #6DB6E3 10%, #6DB6E3 100%);
    background: -o-linear-gradient(top, #6DB6E3 10%, #6DB6E3 100%);
    background: linear-gradient(180deg, #6DB6E3 10%, #6DB6E3 100%);
    outline: none;
    border: none;
    cursor: pointer; }
    footer .footer__button:hover {
      background: -webkit-gradient(linear, left top, right top, from(#FFFFFF), to(#6DB6E3));
      background: -webkit-linear-gradient(left, #FFFFFF 0%, #6DB6E3 100%);
      background: -o-linear-gradient(left, #FFFFFF 0%, #6DB6E3 100%);
      background: linear-gradient(90deg, #FFFFFF 0%, #6DB6E3 100%); }
    footer .footer__button:focus {
      background: #FFFFFF; }
  footer .footer__welcome {
    height: 632px;
    display: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
    @media all and (max-width: 620px) {
      footer .footer__welcome {
        max-width: 300px; } }
  footer .footer__welcome-title {
    font-weight: bold;
    font-size: 41px;
    line-height: 49px;
    margin-bottom: 30px; }
  footer .footer__welcome-subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 158.2%; }
  footer .footer__welcome-button {
    display: block;
    margin: 50px auto 0;
    width: 420px;
    height: 50px; }
    @media all and (max-width: 620px) {
      footer .footer__welcome-button {
        width: 300px; } }
  footer .footer__submit-button {
    display: block;
    margin: 60px 0 0 auto;
    width: 420px;
    height: 50px; }
    @media all and (max-width: 1200px) {
      footer .footer__submit-button {
        width: 320px; } }
    @media all and (max-width: 960px) {
      footer .footer__submit-button {
        width: 100%; } }

#how-it-works.business__man {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: auto; }
  #how-it-works.business__man img {
    margin: 0 auto 200px; }
    @media all and (max-width: 960px) {
      #how-it-works.business__man img {
        width: 100%;
        margin-bottom: 100px; } }
    @media all and (max-width: 620px) {
      #how-it-works.business__man img {
        margin-bottom: 50px; } }

#how-it-works.business__last {
  border-bottom: 3px solid #fff; }
  #how-it-works.business__last img {
    width: 100vw;
    margin: 0; }

#first-screen.index {
  margin-bottom: 50px; }
  @media all and (max-width: 960px) {
    #first-screen.index {
      margin-bottom: 0;
      min-height: 562px;
      height: auto; } }
  @media all and (max-width: 620px) {
    #first-screen.index {
      height: auto;
      min-height: 400px; } }
  #first-screen.index .background-pattern {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    @media all and (max-width: 960px) {
      #first-screen.index .background-pattern {
        left: -200px;
        top: -5%; } }
    @media all and (max-width: 620px) {
      #first-screen.index .background-pattern {
        left: -300px;
        top: -70px; } }

#first-screen.business__container-main .background-pattern {
  top: -100px;
  z-index: -999; }
  @media all and (max-width: 960px) {
    #first-screen.business__container-main .background-pattern {
      left: -20px; } }
  @media all and (max-width: 620px) {
    #first-screen.business__container-main .background-pattern {
      opacity: .3; } }

#first-screen.business__container-main .block p.text {
  font-weight: 500;
  font-size: 18px;
  line-height: 158.2%;
  margin: 0 0 90px;
  text-align: left; }
  @media all and (max-width: 960px) {
    #first-screen.business__container-main .block p.text {
      margin: 0 0 60px;
      font-size: 14px; } }
  #first-screen.business__container-main .block p.text:last-child {
    margin-bottom: 0; }

.business {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  height: auto;
  margin-bottom: 200px; }
  @media all and (max-width: 960px) {
    .business {
      margin-bottom: 80px; } }
  @media all and (max-width: 960px) {
    .business {
      height: 100%;
      min-height: auto;
      padding: 0 0 90px; } }
  .business .background-pattern {
    opacity: .8; }
    @media all and (max-width: 620px) {
      .business .background-pattern {
        opacity: .3; } }
  .business .crypto-container {
    margin-top: 170px; }
    @media all and (max-width: 960px) {
      .business .crypto-container {
        margin-top: 80px; } }
    @media all and (max-width: 620px) {
      .business .crypto-container {
        margin-top: 30px; } }
  @media all and (max-width: 620px) {
    .business {
      margin-bottom: 10px;
      height: auto; } }
  @media all and (max-width: 960px) {
    .business .crypto-title {
      font-size: 34px;
      line-height: 158.2%; } }
  @media all and (max-width: 620px) {
    .business .crypto-title {
      text-align: center;
      margin: 50px 0; } }
  .business__container {
    margin: 60px 20px 0;
    max-width: 1195px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
        -ms-flex-align: baseline;
            align-items: baseline; }
    @media all and (max-width: 620px) {
      .business__container {
        width: 100%;
        margin: 60px 10px 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .business__container-main {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-top: 300px; }
      @media all and (max-width: 1200px) {
        .business__container-main {
          margin-left: 20px;
          margin-right: 20px; } }
      @media all and (max-width: 960px) {
        .business__container-main {
          padding-top: 120px; } }
      .business__container-main .block {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        max-width: 1200px; }
        @media all and (max-width: 620px) {
          .business__container-main .block {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
                -ms-flex-direction: column;
                    flex-direction: column; } }
        .business__container-main .block-title {
          font-weight: bold;
          font-size: 34px;
          line-height: 158.2%;
          margin-bottom: 60px; }
      .business__container-main .block-1 {
        margin-bottom: 150px; }
        @media all and (max-width: 960px) {
          .business__container-main .block-1 {
            margin: 0 0 70px; } }
        .business__container-main .block-1 .description {
          margin-left: 120px; }
          @media all and (max-width: 1200px) {
            .business__container-main .block-1 .description {
              margin-left: 80px; } }
          @media all and (max-width: 960px) {
            .business__container-main .block-1 .description {
              margin-left: 20px;
              text-align: center; }
              .business__container-main .block-1 .description p {
                text-align: center !important; } }
          @media all and (max-width: 620px) {
            .business__container-main .block-1 .description {
              margin-left: 0; }
              .business__container-main .block-1 .description p {
                text-align: center !important; } }
          .business__container-main .block-1 .description img {
            margin-bottom: 40px; }
            .business__container-main .block-1 .description img:last-child {
              margin-bottom: 0; }
      .business__container-main .block-2 {
        margin-bottom: 250px; }
        @media all and (max-width: 960px) {
          .business__container-main .block-2 {
            margin-bottom: 140px; } }
        @media all and (max-width: 620px) {
          .business__container-main .block-2 {
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -webkit-flex-direction: column-reverse;
                -ms-flex-direction: column-reverse;
                    flex-direction: column-reverse;
            margin-bottom: 70px; } }
        .business__container-main .block-2 .description {
          max-width: 574px;
          margin-right: 200px; }
          @media all and (max-width: 1200px) {
            .business__container-main .block-2 .description {
              margin-right: 80px; } }
          @media all and (max-width: 960px) {
            .business__container-main .block-2 .description {
              margin-right: 20px; } }
        .business__container-main .block-2 p {
          margin-bottom: 20px !important; }
      .business__container-main .block-3 {
        margin-bottom: 185px; }
        @media all and (max-width: 960px) {
          .business__container-main .block-3 {
            margin-bottom: 100px; } }
        .business__container-main .block-3 .description {
          max-width: 568px;
          margin-right: 200px; }
          @media all and (max-width: 960px) {
            .business__container-main .block-3 .description {
              margin-right: 0;
              max-width: 300px; } }
        .business__container-main .block-3 p {
          margin-bottom: 40px !important;
          padding-left: 40px; }
          .business__container-main .block-3 p svg {
            top: 8px !important;
            left: 0 !important; }
      .business__container-main .block-4 {
        margin-bottom: 150px; }
        @media all and (max-width: 960px) {
          .business__container-main .block-4 {
            margin-bottom: 100px; }
            .business__container-main .block-4 .block-title {
              margin-left: -320px;
              text-align: center; } }
        @media all and (max-width: 620px) {
          .business__container-main .block-4 {
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -webkit-flex-direction: column-reverse;
                -ms-flex-direction: column-reverse;
                    flex-direction: column-reverse; }
            .business__container-main .block-4 .block-title {
              margin-left: -20px;
              margin-right: -20px;
              text-align: center; } }
        .business__container-main .block-4 .description {
          max-width: 568px;
          margin-left: 20px; }
          @media all and (max-width: 620px) {
            .business__container-main .block-4 .description {
              margin-left: 0; } }
        .business__container-main .block-4 p {
          margin-bottom: 40px !important;
          padding-left: 40px; }
          .business__container-main .block-4 p svg {
            position: absolute;
            top: 8px !important;
            left: 0 !important; }
      .business__container-main .photo1 {
        width: 567px;
        height: 836px; }
        @media all and (max-width: 1200px) {
          .business__container-main .photo1 {
            width: 469px;
            height: 771px; } }
        @media all and (max-width: 960px) {
          .business__container-main .photo1 {
            display: none; } }
      .business__container-main .photo1-tab {
        display: none; }
        @media all and (max-width: 960px) {
          .business__container-main .photo1-tab {
            display: block;
            width: 309px;
            height: 710px; } }
        @media all and (max-width: 620px) {
          .business__container-main .photo1-tab {
            display: none; } }
      .business__container-main .photo1-mobile {
        display: none; }
        @media all and (max-width: 620px) {
          .business__container-main .photo1-mobile {
            display: block;
            width: 303px;
            height: 498px;
            margin-bottom: 40px; } }
      .business__container-main .photo2 {
        width: 571px;
        height: 461px; }
        @media all and (max-width: 1200px) {
          .business__container-main .photo2 {
            display: none; } }
      .business__container-main .photo2-desktop {
        display: none; }
        @media all and (max-width: 1200px) {
          .business__container-main .photo2-desktop {
            display: block;
            width: 571px;
            height: 515px; } }
        @media all and (max-width: 960px) {
          .business__container-main .photo2-desktop {
            display: none; } }
      .business__container-main .photo2-mobile {
        display: none; }
        @media all and (max-width: 960px) {
          .business__container-main .photo2-mobile {
            display: block;
            width: 300px;
            height: 363px;
            margin-bottom: 40px; } }
      .business__container-main .photo3 {
        width: 429px;
        height: 371px; }
        @media all and (max-width: 960px) {
          .business__container-main .photo3 {
            width: 301px;
            height: 260.31px; } }
        @media all and (max-width: 620px) {
          .business__container-main .photo3 {
            width: 363px;
            height: 313.92px; } }
      .business__container-main .photo4 {
        width: 587.38px;
        height: 511.12px; }
        @media all and (max-width: 1200px) {
          .business__container-main .photo4 {
            width: 478.06px;
            height: 416px; } }
        @media all and (max-width: 960px) {
          .business__container-main .photo4 {
            display: none; } }
      .business__container-main .photo4-mob {
        display: none; }
        @media all and (max-width: 960px) {
          .business__container-main .photo4-mob {
            display: block;
            width: 300px;
            height: 374.15px;
            margin-top: 80px; } }
        @media all and (max-width: 620px) {
          .business__container-main .photo4-mob {
            margin-top: 0; } }
    .business__container-motto {
      height: 880px; }
      @media all and (max-width: 960px) {
        .business__container-motto {
          height: 510px; } }
      @media all and (max-width: 620px) {
        .business__container-motto {
          height: 530px; } }
      .business__container-motto .background-pattern {
        bottom: 0; }
        @media all and (max-width: 960px) {
          .business__container-motto .background-pattern img {
            height: 100%; } }
    .business__container-item {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
          -ms-flex: 1;
              flex: 1;
      font-weight: 500;
      font-size: 18px;
      line-height: 158.2%; }
      @media all and (max-width: 620px) {
        .business__container-item {
          max-width: 290px;
          margin: 0 auto;
          text-align: center; } }
      .business__container-item:first-child {
        margin-right: 60px; }
        @media all and (max-width: 620px) {
          .business__container-item:first-child {
            margin-right: auto;
            margin-bottom: 45px; } }
  .business__motto {
    font-weight: bold;
    font-size: 52px;
    line-height: 158.2%;
    max-width: 1195px; }
    @media all and (max-width: 1200px) {
      .business__motto {
        max-width: 855px; } }
    @media all and (max-width: 960px) {
      .business__motto {
        font-size: 34px;
        line-height: 158.2%;
        max-width: 598px; } }
    @media all and (max-width: 620px) {
      .business__motto {
        font-weight: bold;
        font-size: 24px;
        line-height: 158.2%;
        max-width: 300px; } }

@media all and (max-width: 960px) {
  #one {
    max-width: 250px;
    margin: 20px auto 30px; } }

@media all and (max-width: 960px) {
  #zero {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto; } }

.contact {
  border-bottom: 3px solid #fff; }
  @media all and (max-width: 960px) {
    .contact {
      height: auto; } }
  .contact-container {
    max-width: 1200px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media all and (max-width: 1200px) {
      .contact-container {
        margin: 0 20px; } }
    @media all and (max-width: 620px) {
      .contact-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .contact-container img {
      width: 589px;
      height: 543.02px; }
      @media all and (max-width: 1200px) {
        .contact-container img {
          width: 458px;
          height: 422.24px; } }
      @media all and (max-width: 960px) {
        .contact-container img {
          width: 313px;
          height: 288.56px; } }
      @media all and (max-width: 620px) {
        .contact-container img {
          width: 300px;
          height: 288.56px;
          margin: 0 auto 100px; } }
  .contact-info {
    min-width: 665px; }
    @media all and (max-width: 1200px) {
      .contact-info {
        min-width: 475px; } }
    @media all and (max-width: 960px) {
      .contact-info {
        min-width: 314px;
        margin-top: -50px; } }
    @media all and (max-width: 620px) {
      .contact-info {
        min-width: auto;
        margin-top: 80px;
        margin-bottom: 75px; } }
  .contact-title {
    font-weight: bold;
    font-size: 52px;
    line-height: 158.2%;
    margin-bottom: 40px;
    color: #6DB6E3; }
    @media all and (max-width: 960px) {
      .contact-title {
        font-size: 34px;
        line-height: 158.2%; } }
  .contact-subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 158.2%;
    color: #FFFFFF;
    max-width: 472px;
    margin-bottom: 20px; }
    @media all and (max-width: 960px) {
      .contact-subtitle {
        margin: 0 -10px 20px 0; } }
    @media all and (max-width: 620px) {
      .contact-subtitle {
        font-size: 16px; } }
  .contact-more {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 158.2%;
    color: #fff;
    margin-bottom: 60px;
    text-decoration: underline; }
  .contact-button {
    height: 64px;
    width: 240px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 158.2%;
    text-transform: uppercase;
    color: #0F0E21;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #6DB6E3), to(#6DB6E3));
    background: -webkit-linear-gradient(top, #6DB6E3 10%, #6DB6E3 100%);
    background: -o-linear-gradient(top, #6DB6E3 10%, #6DB6E3 100%);
    background: linear-gradient(180deg, #6DB6E3 10%, #6DB6E3 100%);
    outline: none;
    border: none;
    cursor: pointer; }
    .contact-button:hover {
      background: -webkit-gradient(linear, left top, right top, from(#FFFFFF), to(#6DB6E3));
      background: -webkit-linear-gradient(left, #FFFFFF 0%, #6DB6E3 100%);
      background: -o-linear-gradient(left, #FFFFFF 0%, #6DB6E3 100%);
      background: linear-gradient(90deg, #FFFFFF 0%, #6DB6E3 100%); }
    .contact-button:focus {
      background: #FFFFFF; }
    @media all and (max-width: 960px) {
      .contact-button {
        width: 290px; } }
    @media all and (max-width: 620px) {
      .contact-button {
        width: 100%;
        margin: 0 auto; } }
